<template>
	<div class="CircleDetail">
		<w-navTab titleText="详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="CircleDetail-body">
				<div class="CircleDetail-container">
					<div class="CircleDetail-container-top">
						<div class="CircleDetail-container-top-left">
							<div class="top-left-picture">
								<img :src="detail.avatar" mode=""></img>
								<div class="top-left-picture-mark">
									<img src="../../assets/img/icon420.png" mode="" v-if="detail.sex == 2"></img>
									<img src="../../assets/img/icon421.png" mode="" v-else></img>
								</div>
							</div>
							<div class="top-left-box">
								<div class="top-left-box-top">
									<div class="top-title">{{detail.user_name}}</div>
									<div class="top-title-role">{{detail.level_name}}</div>
								</div>
								<div class="top-left-box-bottom">
									<div class="bottom-title">
										<van-icon style="margin-right: 8rpx;" name="location" color="#3377FF"
											v-if="isCity()" />
										<van-icon style="margin-right: 8rpx;" name="location" color="#FF6969" v-else />
										{{detail.district_name}}
									</div>
									<div class="bottom-time">{{detail.create_time}}</div>
								</div>
							</div>
						</div>
						<div class="CircleDetail-container-top-right">
							<div class="CircleDetail-container-top-right-title" v-if="detail.is_subscribe == 0"
								@click="onFollow(detail.user_id)">关注</div>
							<div class="CircleDetail-container-top-right-title active-color" v-else
								@click="onCancelFollow(detail.user_id)">已关注</div>
							<div class="CircleDetail-container-top-right-sort" @click="reportShow = true">
								<van-icon name="ellipsis" color="#AAAAA9" size="20" />
							</div>
						</div>
					</div>
					<div class="item-popup" v-if="reportShow" @click.stop="isShowPicker = true">
						<div class="item-popup-arrow">
							<img src="../../assets/img/icon37.png" alt="">
						</div>
						<div class="item-popup-box">
							<div class="popup-picture"><img src="../../assets/img/icon34.png" alt=""></div>
							<div class="popup-title">举报</div>
							<div class="popup-text">虚假信息、广告、与主题不符等</div>
						</div>
					</div>
					<div class="CircleDetail-container-pictureList">
						<div class="pictureList-item" v-for="(item,index) in detail.photos"
							@click="onPreview(detail.photos, index)"><img :src="item" mode=""></img></div>
					</div>
					<div class="CircleDetail-container-title">
						{{detail.content}}
					</div>
					<div class="CircleDetail-container-activity"
						@click.stop="$router.push({ name: 'ActivityDetail',params:{activityId: detail.activity_id}})">
						<div class="container-activity-left-body">
							<div class="container-activity-left">
								<div class="container-activity-left-box"><img src="../../assets/img/icon39.png"
										mode=""></img></div>
							</div>
							<div class="container-activity-title">{{detail.activity_title}}</div>
						</div>
						<div class="container-activity-right">
							<van-icon name="arrow" color="#3377FF" size="14" />
						</div>
					</div>
					<div class="CircleDetail-container-footer"
						@click="$router.push({ name: 'CirleDetailPraise',params:{id: momentId}})">
						<div class="CircleDetail-container-footer-left">
							<div class="CircleDetail-container-footer-left-wrapper">
								<div class="CircleDetail-container-footer-left-wrapper-picture"
									v-for="(item,index) in detail.stars_data"
									:style="{zIndex: 1000 - index, position: 'absolute', left: (index * 22 - 5) + 'px' }">
									<img :src="item.avatar" mode=""></img>
								</div>
							</div>
							<!-- <van-icon style="background-color: #F5F6F8; border-radius: 50%; margin-left: 110px;" name="ellipsis" color="#AAAAA9" size="32" /> -->
						</div>
						<div class="CircleDetail-container-footer-right">
							{{detail.stars}}人点赞
							<van-icon name="arrow" color="#AAAAA9" size="24" />
						</div>
					</div>
				</div>
				<!-- 评论 -->
				<div class="CircleDetail-comment">
					<div class="CircleDetail-comment-title">评论 {{total}}</div>
					<!-- 主评论 -->
					<w-list class="list-view sign-in-scroll" :loading="loading" :finished="finished" :is-empty="isEmpty"
						@onLoad="onLoad">
						<div class="CircleDetail-comment-body" v-for="(item,index) in commentPagesList">
							<div class="CircleDetail-comment-body-left"><img :src="item.from_avatar" mode=""></img>
							</div>
							<div class="CircleDetail-comment-body-right">
								<div class="CircleDetail-comment-body-right-box">
									<div class="comment-body-right-name">
										<div class="comment-body-right-name-left">
											{{item.from_real_name}}{{item.district_name}}{{item.level_name}}
										</div>
										<div class="comment-body-right-name-right"
											v-if="userInfo.id == item.from_user_id" @click="onCommentDel(item,index)">
											删除
										</div>
									</div>
									<div class="comment-body-right-text">{{item.content}}</div>
									<div class="comment-body-right-bottom">
										<div class="comment-body-right-bottom-time">{{item.create_time}}</div>
										<div class="comment-body-right-bottom-right">
											<van-icon style="margin-right: 8px;" name="chat-o" color="#777B81" size="18"
												@click="OnCreate(item.id,item,index)" />
											<van-icon style="margin-right: 8px;" name="good-job-o" color="#3377FF"
												size="18" v-if="item.is_star == 1"
												@click="onCancelCommentPraise(item,index)" />
											<van-icon style="margin-right: 8px;" name="good-job-o" color="#777B81"
												size="18" v-else @click="onCommentPraise(item,index)" />
											{{item.likes}}
										</div>
									</div>
								</div>
								<!-- 子级评论 -->
								<div class="CircleDetail-comment-body-right-box-child" v-for="(vo,voIndex) in item.child_comments">
									<div class="CircleDetail-comment-body-right-box-child-picture"><img
											:src="vo.from_avatar" mode=""></img></div>
									<div class="CircleDetail-comment-body-right-box-child-info">
										<div class="CircleDetail-comment-body-right-box-child-info-title">
											<div class="CircleDetail-comment-body-right-box-child-info-title-left">
												{{vo.from_real_name}} {{vo.district_name}}{{vo.level_name}}
												<van-icon name="play" v-if="vo.reply_real_name" />
												{{vo.reply_real_name}}
											</div>
											<div class="CircleDetail-comment-body-right-box-child-info-title-right"
												v-if="userInfo.id == vo.from_user_id"
												@click="onCommentDel(vo,index,voIndex)">
												删除
											</div>
										</div>
										<div class="CircleDetail-comment-body-right-box-child-info-text">{{vo.content}}
										</div>
										<div class="CircleDetail-comment-body-right-box-child-info-bottom">
											<div class="CircleDetail-comment-body-right-box-child-info-bottom-left">
												{{vo.create_time}}
											</div>
											<div class="CircleDetail-comment-body-right-box-child-info-bottom-right">
												<van-icon style="margin-right: 8px;" name="chat-o" color="#777B81"
													size="18" @click="OnCreate(item.id,vo,index)" />
												<van-icon style="margin-right: 8px;" name="good-job-o" color="#3377FF"
													size="18" v-if="vo.is_star == 1"
													@click="onCancelCommentPraise(vo,index,voIndex)" />

												<van-icon style="margin-right: 8px;" name="good-job-o" color="#777B81"
													size="18" v-else @click="onCommentPraise(vo,index,voIndex)" />
												{{vo.likes}}
											</div>
										</div>
									</div>
								</div>
								<div class="child-more-box" @click="getSubCommentPages(index,item.id)"
									v-if="item.child_comments.length < item.child_total">
									点击加载更多
									<van-icon style="margin-left: 6px;" name="arrow-down" />
								</div>
							</div>
						</div>
					</w-list>
				</div>
			</div>
			<div class="CircleDetail-footer">
				<div class="CircleDetail-footer-leftBtn active-btn" v-if="detail.is_star == 1"
					@click="onCancelPraise(detail.moment_id)">
					<van-icon name="good-job-o" color="#3377FF" size="25" />
					</u-icon>
				</div>
				<div class="CircleDetail-footer-leftBtn" v-else @click="onPraise(detail.moment_id)">
					<van-icon name="good-job-o" color="#777b81" size="25" />
					</u-icon>
				</div>
				<div class="CircleDetail-footer-input"><input v-model="content" type="text" ref="ipt"
						:placeholder="placeholderText" /></div>
				<div class="CircleDetail-footer-rightBtn" @click="CommentCreate">发送</div>
			</div>
			<div class="popup" v-if="reportShow" @click.stop="reportShow = false"></div>
			<van-popup v-model="isShowPicker">
				<div class="showPopup" @click.stop="">
					<div class="showPopup-box">
						<div class="showPopup-title">
							举报违规
						</div>
						<div class="showPopup-textarea">
							<textarea v-model="reason" placeholder="请输入举报原因"></textarea>
						</div>
					</div>
					<div class="showPopup-footer">
						<div class="showPopup-footer-left" @click.stop="isShowPicker = false">取消</div>
						<div class="showPopup-footer-right" @click.stop="onSubmit()">提交</div>
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
	import Circle from '@/api/circle';
	import Vant from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: "CircleDetail",
		inject: ['themeColor', 'className', 'isCity', 'isDistrict'],
		data() {
			return {
				detail: "",
				total: "",
				pagesIndex: "",
				momentId: "",
				commentId: 0,
				reply_userId: 0,
				reply_commentId: 0,
				placeholderText: '请输入评论内容',
				commentPagesList: [],
				content: "",
				page: 1,
				limit: 10,
				subPage: 2,
				subLimit: 3,
				reportShow: false,
				isShowPicker: false,
				reason: "",
				loading: true,
				finished: false,
				isEmpty: false,
				loadingPage: true,
			}
		},
		created() {
			this.momentId = this.$route.params.id
			this.GetDetail()
			this.onLoad()
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo;
			}
		},
		methods: {
			// 删除评论
			onCommentDel(item, index, voIndex) {
				Vant.Dialog.confirm({
						title: '删除提示',
						message: '您确认删除这条评论吗？',
					})
					.then(() => {
						Circle.commentDel({
								comment_pri_id: item.id
							})
							.then(result => {
								if (item.comment_id == 0) {
									this.commentPagesList.splice(index, 1)
								} else {
									this.commentPagesList[index].child_comments.splice(index, 1)
								}
								Vant.Toast.success('删除评论成功')
							}, error => {
								Vant.Toast.fail(error.msg)
							})
					})

			},
			// 预览图片
			onPreview(vo, index) {
				Vant.ImagePreview({
					images: vo,
					startPosition: index,
				});
			},
			// 详情
			GetDetail() {
				Circle.detail({
						moment_id: this.momentId
					})
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 主评论
			onLoad() {
				let params = {
					page: this.page,
					limit: this.limit,
					moment_id: this.momentId
				}
				Circle.commentPages(params)
					.then(result => {

						result.data.lists.forEach(item => {
							item.page = 2
							item.limit = 3
							return item
						})

						this.commentPagesList = this.commentPagesList.concat(result.data.lists)
						this.total = result.data.total
						this.page++
						this.loading = false;
						this.finished = true;
						this.isEmpty = this.commentPagesList.length <= 0;
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 子评论
			SubCommentPages(index, id) {
				const topComment = this.commentPagesList[index]
				Circle.subCommentPages({
					comment_pri_id: id,
					page: topComment.page,
					limit: topComment.limit
				}).then(result => {
					this.commentPagesList[index].page++
					this.commentPagesList[index].child_comments = topComment.child_comments.concat(result.data
						.lists)
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 加载更多子评论
			getSubCommentPages(index, id) {
				this.SubCommentPages(index, id)
			},
			// 点击回复评论
			OnCreate(id, item, index) {
				this.$refs.ipt.focus()
				this.pagesIndex = index
				this.commentId = id
				this.reply_userId = item.from_user_id
				this.reply_commentId = item.id
				this.placeholderText = '回复@ ' + item.from_real_name
			},
			// 发布评论
			CommentCreate() {
				let params = {
					moment_id: this.momentId,
					comment_id: this.commentId,
					reply_user_id: this.reply_userId,
					reply_comment_id: this.reply_commentId,
					content: this.content
				}
				Circle.commentCreate(params)
					.then(result => {
						this.content = ''
						Vant.Toast.success('发布评论成功')
						if (result.data.comment_id == 0) {
							let list = result.data.child_comments = []
							this.commentPagesList.unshift(result.data)
						} else {
							this.commentPagesList[this.pagesIndex].child_comments.push(result.data)
						}
						this.commentId = 0
						this.reply_userId = ''
						this.reply_commentId = ''
						this.placeholderText = '请输入评论内容'
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 举报
			onSubmit() {
				let params = {
					moment_id: this.detail.moment_id,
					type: 1,
					reason: this.reason
				}
				Circle.report(params)
					.then(result => {
						Vant.Toast.success('举报成功')
						this.reason = ''
						this.isShowPicker = false
						this.reportShow = false
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 评论点赞
			onCommentPraise(item, index, voIndex) {
				console.log(1)
				console.log("点赞")
				Circle.starComment({
						id: item.id
					})
					.then(result => {
						if (item.comment_id == 0) {
							this.commentPagesList[index].is_star = 1
							this.commentPagesList[index].likes++
						} else {
							this.commentPagesList[index].child_comments[voIndex].is_star = 1
							this.commentPagesList[index].child_comments[voIndex].likes++
						}
						Vant.Toast.success('点赞成功')
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 评论取消点赞
			onCancelCommentPraise(item, index, voIndex) {
				console.log(2)
				console.log("取消点赞")
				Circle.cancelStarComment({
						id: item.id
					})
					.then(result => {
						if (item.comment_id == 0) {
							this.commentPagesList[index].is_star = 0
							this.commentPagesList[index].likes--
						} else {
							this.commentPagesList[index].child_comments[voIndex].is_star = 0
							this.commentPagesList[index].child_comments[voIndex].likes--
						}
						Vant.Toast('取消点赞')
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 点赞
			onPraise(id, index) {
				Circle.starMoment({
						moment_id: this.momentId
					})
					.then(result => {
						this.detail.is_star = 1
						this.detail.stars++
						Vant.Toast.success('点赞成功')
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 取消点赞
			onCancelPraise(id, index) {
				Circle.cancelStarMoment({
					moment_id: this.momentId
				}).then(result => {
					this.detail.is_star = 0
					this.detail.stars--
					Vant.Toast('取消点赞成功')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 关注
			onFollow(subscribe_id) {
				Circle.subscribe({
					subscribe_id
				}).then(result => {
					this.detail.is_subscribe = 1
					Vant.Toast('关注成功')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 取消关注
			onCancelFollow(subscribe_id) {
				Circle.cancelSubscribe({
					subscribe_id
				}).then(result => {
					this.detail.is_subscribe = 0
					Vant.Toast('取消成功')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
		},
		components: {
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.CircleDetail.city {
		.top-left-box-bottom {
			.bottom-title {
				color: #3377FF;
			}
		}

		.CircleDetail-container-top-right-title {
			background-color: #3377FF;
		}

		.CircleDetail-footer-rightBtn {
			background-color: #3377FF;
		}
	}

	.CircleDetail.district {
		.top-left-box-bottom {
			.bottom-title {
				color: #FF6969;
			}
		}

		.CircleDetail-container-top-right-title {
			background-color: #FF6969;
		}

		.CircleDetail-footer-rightBtn {
			background-color: #FF6969;
		}
	}

	.CircleDetail {
		background-color: #fff;
		padding-bottom: 60px;
		box-sizing: border-box;
		.container {
			padding-top: 15px;
			box-sizing: border-box;
			.CircleDetail-body {
				padding: 0 8px;
				box-sizing: border-box;
				.CircleDetail-container {
					.item-popup {
						width: 358px;
						background: #FFF;
						border-radius: 6px;
						position: absolute;
						z-index: 1001;
						top: 50px;
						left: 8px;

						.item-popup-arrow {
							width: 14px;
							height: 8px;
							position: absolute;
							top: -8px;
							right: 6px;

							img {
								position: absolute;
								z-index: 1001;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.item-popup-box {
							display: flex;
							align-items: center;
							padding: 12px 0;
							padding-left: 12px;
							border-bottom: 1px dashed #F5F6F8;

							&.item-popup-box:last-child {
								border: 0;
							}

							.popup-picture {
								width: 24px;
								height: 24px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.popup-title {
								font-size: 14px;
								line-height: 20px;
								color: #44484E;
								margin: 0 9px;
							}

							.popup-text {
								font-size: 12px;
								line-height: 17px;
								color: #777B81;
							}
						}
					}

					.CircleDetail-container-top {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.CircleDetail-container-top-left {
							display: flex;
							align-items: center;

							.top-left-picture {
								position: relative;
								width: 40px;
								height: 40px;
								border-radius: 50%;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}

								.top-left-picture-mark {
									width: 22px;
									height: 22px;
									box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
									border-radius: 100px;
									position: absolute;
									bottom: -4px;
									right: -4px;
									z-index: 10;

									img {
										width: 100%;
										height: 100%;
										border-radius: 100px;
										object-fit: cover;
									}
								}
							}

							.top-left-box {
								padding-left: 6px;

								.top-left-box-top {
									display: flex;
									align-items: center;

									.top-title {
										font-weight: bold;
										font-size: 15px;
										line-height: 22px;
										color: #333;
									}

									.top-title-role {
										font-size: 13px;
										line-height: 19px;
										color: #777;
										margin-left: 4px;
									}
								}

								.top-left-box-bottom {
									display: flex;
									align-items: center;

									.bottom-title {
										font-size: 12px;
										line-height: 17px;
									}

									.bottom-time {
										font-size: 12px;
										line-height: 17px;
										color: #777;
										margin-left: 4px;
									}
								}
							}
						}

						.CircleDetail-container-top-right {
							display: flex;
							align-items: center;

							.CircleDetail-container-top-right-title {
								font-size: 12px;
								color: #fff;
								padding: 5px 20px;
								border-radius: 100px;
								margin-right: 4px;

								&.active-color {
									background: #F5F6F8;
									color: #777b81;
								}
							}

							.CircleDetail-container-top-right-sort {
								padding: 3px;
								border-radius: 50%;
								background: #f5f6f8;
								text-align: center;
								transform: rotate(90deg);
							}
						}
					}

					.CircleDetail-container-pictureList {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						margin-top: 11px;

						.pictureList-item:last-child {
							margin-right: 0;
						}

						.item-pictureList-item:nth-child(3) {
							margin-right: 0;
						}

						.pictureList-item {
							width: 109px;
							height: 100px;
							border-radius: 2px;
							margin-right: 8px;
							margin-bottom: 8px;

							img {
								width: 100%;
								height: 100%;
								border-radius: 2px;
								object-fit: cover;
							}
						}
					}

					.CircleDetail-container-title {
						margin-top: 4px;
						font-size: 15px;
						line-height: 1.6;
						color: #333;
					}

					.CircleDetail-container-activity {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: rgba(244, 160, 20, 0.1);
						border-radius: 12px;
						padding: 5px 12px;
						margin: 24px 0;
						box-sizing: border-box;

						.container-activity-left-body {
							display: flex;
							align-items: center;

							.container-activity-left {
								.container-activity-left-box {
									width: 30px;
									height: 30px;

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
							}

							.container-activity-title {
								font-size: 15px;
								line-height: 22px;
								color: #777;
								display: flex;
								align-items: center;
								margin-left: 12px;

								&::before {
									content: '';
									width: 1px;
									height: 20px;
									margin-right: 12px;
									background-color: rgba(170, 170, 169, 0.5);
								}
							}
						}

						.container-activity-right {
							width: 24px;
							height: 24px;
							background-color: #fff;
							box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
							border-radius: 100px;
							text-align: center;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}

					.CircleDetail-container-footer {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 24px 0;
						border-top: 1px solid #f5f6f8;

						.CircleDetail-container-footer-left {
							display: flex;
							align-items: center;

							.CircleDetail-container-footer-left-wrapper {
								display: flex;
								align-items: center;
								position: relative;
								left: 3px;

								.CircleDetail-container-footer-left-wrapper-picture {
									width: 30px;
									height: 30px;
									border-radius: 50%;
									border: 2px solid #FFF;

									img {
										width: 30px;
										height: 100%;
										object-fit: cover;
										border-radius: 50%;
									}
								}
							}
						}

						.CircleDetail-container-footer-right {
							font-size: 15px;
							line-height: 22px;
							color: #777;
							display: flex;
							align-items: center;
						}
					}
				}

				// 评论
				.CircleDetail-comment {
					.CircleDetail-comment-title {
						font-size: 17px;
						line-height: 20px;
						color: #1c1d1d;
						margin-bottom: 24px;
					}

					// 评论一级
					.CircleDetail-comment-body {
						display: flex;
						width: 100%;
						margin-top: 24px;

						&.CircleDetail-comment-body:first-child {
							margin-top: 0;
						}

						.CircleDetail-comment-body-left {
							width: 32px;
							height: 32px;
							min-width: 32px;
							border-radius: 50%;
							margin-right: 5px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 50%;
							}
						}

						.CircleDetail-comment-body-right {
							width: 100%;

							.CircleDetail-comment-body-right-box {
								.comment-body-right-name {
									display: flex;
									justify-content: space-between;
									align-items: center;

									.comment-body-right-name-left {
										font-weight: bold;
										font-size: 14px;
										line-height: 20px;
										color: #1c1d1d;
									}

									.comment-body-right-name-right {
										font-size: 14px;
										line-height: 20px;
										color: rgba(249, 97, 93, 1);
									}
								}

								.comment-body-right-text {
									font-size: 14px;
									line-height: 20px;
									color: #1c1d1d;
									margin: 4px 0;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
									overflow: hidden;
								}

								.comment-body-right-bottom {
									display: flex;
									justify-content: space-between;
									align-items: center;

									.comment-body-right-bottom-time {
										font-size: 12px;
										line-height: 18px;
										color: #777b81;
									}

									.comment-body-right-bottom-right {
										font-size: 12px;
										line-height: 18px;
										color: #777b81;
										display: flex;
										align-items: center;
									}
								}
							}

							// 评论二级
							.CircleDetail-comment-body-right-box-child {
								display: flex;
								margin-top: 16px;

								.CircleDetail-comment-body-right-box-child-picture {
									width: 24px;
									min-width: 24px;
									height: 24px;
									border-radius: 50%;

									img {
										width: 100%;
										height: 100%;
										border-radius: 50%;
										object-fit: cover;
									}
								}

								.CircleDetail-comment-body-right-box-child-info {
									width: 100%;
									padding-left: 4px;

									.CircleDetail-comment-body-right-box-child-info-title {
										display: flex;
										justify-content: space-between;
										align-items: center;

										.CircleDetail-comment-body-right-box-child-info-title-left {
											font-weight: bold;
											font-size: 13px;
											line-height: 20px;
											color: #1c1d1d;
										}

										.CircleDetail-comment-body-right-box-child-info-title-right {
											font-size: 13px;
											line-height: 20px;
											color: rgba(249, 97, 93, 1);
										}
									}

									.CircleDetail-comment-body-right-box-child-info-text {
										font-size: 13px;
										line-height: 18px;
										color: #1c1d1d;
										margin: 4px 0;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
										overflow: hidden;
									}

									.CircleDetail-comment-body-right-box-child-info-bottom {
										display: flex;
										justify-content: space-between;

										.CircleDetail-comment-body-right-box-child-info-bottom-left {
											font-size: 12px;
											line-height: 18px;
											color: #777b81;
										}

										.CircleDetail-comment-body-right-box-child-info-bottom-right {
											font-size: 12px;
											line-height: 18px;
											color: #777b81;
											display: flex;
											align-items: center;
										}
									}
								}
							}

							.child-more-box {
								margin-top: 5px;
								color: #aaa;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}
			}

			.CircleDetail-footer {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid #f5f6f8;
				padding: 8px 0;
				background: #fff;
				position: fixed;
				bottom: 0;
				left: 0;
				box-sizing: border-box;

				.CircleDetail-footer-leftBtn {
					background: #F2F2F2;
					padding: 5px;
					border-radius: 100px;
					margin: 0 8px;
					text-align: center;

					&.active-btn {
						background-color: #EEF7FF;
					}
				}

				.CircleDetail-footer-input {
					background: #f5f6f8;
					border-radius: 100px;
					flex: 1;
					line-height: 32px;
					margin-right: 8px;

					input {
						width: 100%;
						border: 0;
						padding-left: 8px;
						height: 32px;
						background: #f5f6f8;
						border-radius: 100px;
					}
				}

				.CircleDetail-footer-rightBtn {
					font-size: 14px;
					line-height: 20px;
					color: #fff;
					padding: 6px 18px;
					// background: #3377FF;
					border-radius: 100px;
					margin: 0 8px;
				}
			}

			.ShowComment {
				.ShowComment-textarea {
					textarea {
						width: 100%;
						height: 100%;
						background: #FFF;
						border: 0;
						font-size: 14px;
						line-height: 21px;
						color: #222;
						padding: 8px;
						box-sizing: border-box;
					}
				}
			}

			.popup {
				width: 100%;
				min-height: 100vh;
				background-color: rgba(0, 0, 0, .3);
				position: fixed;
				top: 0;
				left: 0;
				z-index: 1000;
			}

			/deep/.van-overlay {
				background-color: rgba(0, 0, 0, .3);
			}

			.van-popup {
				border-radius: 6px;
				overflow: hidden;
			}

			.showPopup {
				background: #FFF;
				border-radius: 6px;
				overflow: hidden;

				.showPopup-box {
					padding: 15px;

					.showPopup-title {
						font-weight: bold;
						font-size: 17px;
						line-height: 28px;
						text-align: center;
						color: #222;
					}

					.showPopup-textarea {
						margin-top: 9px;

						textarea {
							width: 266px;
							height: 107px;
							background: #F2F2F2;
							border: 0;
							resize: none;
							padding-left: 5px;
						}
					}
				}

				.showPopup-footer {
					display: flex;
					align-items: center;

					.showPopup-footer-left {
						flex: 1;
						font-size: 17px;
						line-height: 28px;
						text-align: center;
						color: #FFF;
						background: #FF6969;
						padding: 8px 0;
					}

					.showPopup-footer-right {
						flex: 1;
						font-size: 17px;
						line-height: 28px;
						text-align: center;
						color: #FFF;
						background: #3377FF;
						padding: 8px 0;
					}
				}
			}
		}
	}
</style>
